import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import MumblesBGImage from "../components/MumblesBGImage"
import Image from "gatsby-image"
import Layout from "../components/Layout"
import AboutMeContent from "../components/content/AboutMeContent"

const getImage = graphql`
  {
    fluidImage: file(relativePath: { eq: "sarah_v2.png" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function about() {
  const {
    fluidImage: {
      childImageSharp: { fluid: image },
    },
  } = useStaticQuery(getImage)
  return (
    <Layout>
      <MumblesBGImage>
        <div className="hero-image-canvas"></div>
      </MumblesBGImage>
      <div className="contents-wrapper">
        <div className="text-container">
          <h1>Dr Sarah Hartley</h1>
        </div>
        <div className="text-container">
          <p className="block-quote">
            <em>
              “The human psyche, like human bones, is strongly inclined towards
              self-healing.”
            </em>
            <br />John Bowlby
          </p>
          <Image
            fluid={image}
            style={{
              marginTop: `3rem`,
              maxHeight: `470px`,
              maxWidth: `700px`,
            }}
          />
        </div>
        {/* <div className="about-grid"> */}
        <div className="text-container">
          <AboutMeContent />
        </div>
      </div>
    </Layout>
  )
}

import React from "react"
import { Link } from "gatsby"

export default function AboutMeContent() {
  return (
    <div>
      <p>
        Sarah's career in mental health began over 16 years ago, when she
        started work at an acute inpatient hospital in the UK.
      </p>

      <p>
        She graduated from her Doctorate in Clinical Psychology at the
        University of Oxford in 2009, having completed a specialist placement at
        a Trauma Service, where she worked predominantly with patients diagnosed
        with Post-Traumatic Stress Disorder.
      </p>

      <p>
        Moving from the UK to Sydney in 2009, Sarah spent the next nine years
        working in Private Practice, the Forensic Service and the university
        sector, as a Clinical Supervisor on the University of Technology Sydney
        Masters course in Clinical Psychology.
      </p>

      <p>
        Sarah moved to Perth in 2018 and has continued her work in Private
        Practice, expanding her practice to include Couples Therapy.
      </p>

      <p>
        Sarah strives to develop a strong therapeutic relationship with all her
        clients, based on trust, compassion and mutual respect. She has found
        that creating a safe relational space helps her clients to develop a
        compassionate understanding of their own history and current
        difficulties, which is fundamental to working through their distress and
        beginning to achieve their goals.
      </p>

      <p>
        To find out more about the therapies Sarah uses, please visit{" "}
        <Link to="/approach">the Approach section.</Link>
      </p>

      <h2>Qualifications</h2>
      <ul className="hanging-indent-list">
        <li>
          <i className="fa fa-chevron-right"></i>
          Doctorate Clinical Psychology (DClinPsych) - University of Oxford
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Postgraduate Diploma in Mental Health Studies (PostGradDip) -
          University of Southampton
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Bachelor of Science (Hons) in Psychology with Sociology (BSc hons) -
          University of Bath
        </li>
      </ul>
      <h2>Registrations and memberships</h2>
      <ul className="hanging-indent-list">
        <li>
          <i className="fa fa-chevron-right"></i>
          Registration with Australian Health Practitioner Regulation Agency
          (PSY0001354348):
          <ul className="hanging-indent-list">
            <li>
              <i className="fa fa-chevron-right"></i>
              Endorsement for Clinical Psychology
            </li>
            <li>
              <i className="fa fa-chevron-right"></i>
              Supervisor status
            </li>
          </ul>
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Member of the Australian Psychological Society (MAPS):
          <ul className="hanging-indent-list">
            <li>
              <i className="fa fa-chevron-right"></i>
              Eligible for membership of the APS College of Clinical
              Psychologists
            </li>
          </ul>
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Member of the International Centre for Excellence in Emotionally
          Focused Therapy (ICEEFT)
        </li>
      </ul>
      <h2>Areas of interest</h2>
      <ul className="hanging-indent-list">
        <li>
          <i className="fa fa-chevron-right"></i>
          Post-Tramautic Stress Disorder / adjustment difficulties
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Trauma
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Anxiety Disorders
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Mood Disorders (Depression and Bipolar Disorder)
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Low self-esteem
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Obsessive Compulsive Disorder
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Relationship Difficulties
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Grief and loss
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Difficulties with emotion regulation and distress tolerance
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Psychotic disorders (provided they are stabilised enough for work in a
          private practice setting)
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Workers Compensation Matters
        </li>
        <li>
          <i className="fa fa-chevron-right"></i>
          Parenting Challenges
        </li>
      </ul>
      <p>Please note Sarah does not offer court assessments.</p>

      <h2>Publications</h2>
      <p>
        Hartley et al (2016) "The nature of posttraumatic growth in adult
        survivors of childhood sexual abuse"{" "}
        <em>Journal of Child Sexual Abuse</em>, 25 (2): 201-20.
      </p>
    </div>
  )
}
